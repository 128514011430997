$brand-primary: #f60;
$body-color: rgb(83, 83, 83);

footer {
  background-color: $brand-primary;
  color: #fff;
}

header, footer {
  height: 10vh;
  font-size: 4vh;
  text-transform: uppercase;
}

section {
  height:90vh;
  overflow: hidden;
}

header {
  opacity: 0;

}



/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
  transition: all 1s ease;
}
.fade-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}

@import '~bootstrap/scss/bootstrap.scss';
@import 'fl-icon-font.css';
@import '~weather-icons/sass/weather-icons.scss';