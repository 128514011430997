@font-face {
  font-family: 'fl-cloud';
  src:
    url('/fonts/fl-cloud.ttf?xqo8z9') format('truetype'),
    url('/fonts/fl-cloud.woff?xqo8z9') format('woff'),
    url('/fonts/fl-cloud.svg?xqo8z9#fl-cloud') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fc-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fl-cloud' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fc-lg-intercom:before {
  content: "\e97c";
}
.fc-lg-data-format-csv:before {
  content: "\e973";
}
.fc-lg-data-format-pdf:before {
  content: "\e974";
}
.fc-lg-data-format-xls:before {
  content: "\e975";
}
.fc-lg-administration:before {
  content: "\e96e";
}
.fc-lg-pony-farm:before {
  content: "\e968";
}
.fc-lg-settings:before {
  content: "\e966";
}
.fc-lg-cl-world:before {
  content: "\e962";
}
.fc-lg-cl-attachment:before {
  content: "\e943";
}
.fc-lg-cl-pacman:before {
  content: "\e960";
}
.fc-lg-cl-leaf:before {
  content: "\e95f";
}
.fc-lg-cl-bookmark:before {
  content: "\e95a";
}
.fc-lg-cl-compass:before {
  content: "\e95b";
}
.fc-lg-cl-cube:before {
  content: "\e95c";
}
.fc-lg-cl-lifebuoy:before {
  content: "\e95d";
}
.fc-lg-cl-paper-plane:before {
  content: "\e95e";
}
.fc-lg-widget-textarea:before {
  content: "\e92e";
}
.fc-lg-widget-check:before {
  content: "\e92f";
}
.fc-lg-widget-datetime:before {
  content: "\e930";
}
.fc-input-widget-reference:before {
  content: "\e947";
}
.fc-lg-widget-dropdown:before {
  content: "\e931";
}
.fc-lg-widget-markdown:before {
  content: "\e932";
}
.fc-lg-widget-mediaselect:before {
  content: "\e933";
}
.fc-lg-widget-radio:before {
  content: "\e934";
}
.fc-lg-widget-slider:before {
  content: "\e935";
}
.fc-lg-widget-switch:before {
  content: "\e936";
}
.fc-lg-widget-textfield:before {
  content: "\e937";
}
.fc-input-type-reference:before {
  content: "\e948";
}
.fc-lg-type-boolean:before {
  content: "\e938";
}
.fc-lg-type-datetime:before {
  content: "\e939";
}
.fc-lg-type-longtext:before {
  content: "\e93a";
}
.fc-lg-type-media:before {
  content: "\e93b";
}
.fc-lg-type-number:before {
  content: "\e93c";
}
.fc-lg-type-text:before {
  content: "\e93d";
}
.fc-lg-social-bench:before {
  content: "\e92d";
}
.fc-lg-media:before {
  content: "\e928";
}
.fc-lg-content:before {
  content: "\e929";
}
.fc-lg-reporting:before {
  content: "\e922";
}
.fc-lg-cloud-permissions:before {
  content: "\e949";
}
.fc-lg-monitoring:before {
  content: "\e691";
}
.fc-lg-all:before {
  content: "\e800";
}
.fc-lg-social-crm:before {
  content: "\e908";
}
.fc-lg-dashboard:before {
  content: "\e603";
}
.fc-lg-benchmarking:before {
  content: "\e801";
}
.fc-lg-publisher:before {
  content: "\e803";
}
.fc-lg-communication:before {
  content: "\e601";
}
.fc-lg-engagement:before {
  content: "\e605";
}
.fc-lg-advertising:before {
  content: "\e608";
}
.fc-lg_advertising_tw:before {
  content: "\e609";
}
.fc-censhare:before {
  content: "\e977";
}
.fc-distribution-template:before {
  content: "\e976";
}
.fc-companyadmin:before {
  content: "\e970";
}
.fc-teamadmin:before {
  content: "\e971";
}
.fc-security:before {
  content: "\e96f";
}
.fc-resize:before {
  content: "\e96d";
  color: #535353;
}
.fc-carousel:before {
  content: "\e969";
}
.fc-slideshow:before {
  content: "\e96a";
}
.fc-event:before {
  content: "\e967";
}
.fc-inbox:before {
  content: "\e965";
}
.fc-draft:before {
  content: "\e963";
}
.fc-resolve-all:before {
  content: "\e964";
}
.fc-beta:before {
  content: "\e959";
}
.fc-merge:before {
  content: "\e958";
}
.fc-reaction-angry:before {
  content: "\e952";
}
.fc-reaction-haha:before {
  content: "\e953";
}
.fc-reaction-like:before {
  content: "\e954";
}
.fc-reaction-love:before {
  content: "\e955";
}
.fc-reaction-sad:before {
  content: "\e956";
}
.fc-reaction-wow:before {
  content: "\e957";
}
.fc-custom-columns:before {
  content: "\e94d";
}
.fc-filter:before {
  content: "\e94e";
  color: #535353;
}
.fc-pause:before {
  content: "\e94f";
}
.fc-play:before {
  content: "\e950";
}
.fc-budget:before {
  content: "\e942";
}
.fc-format-landscape:before {
  content: "\e940";
}
.fc-format-portrait:before {
  content: "\e941";
}
.fc-container:before {
  content: "\e92b";
}
.fc-content-types:before {
  content: "\e92c";
}
.fc-api:before {
  content: "\e92a";
}
.fc-reporting-template:before {
  content: "\e951";
}
.fc-add-widget:before {
  content: "\e923";
}
.fc-scheduled-report:before {
  content: "\e924";
}
.fc-slides:before {
  content: "\e925";
}
.fc-report:before {
  content: "\e926";
}
.fc-inspector:before {
  content: "\e91b";
}
.fc-profile:before {
  content: "\e91c";
}
.fc-administration:before {
  content: "\e913";
}
.fc-kn-post:before {
  content: "\e912";
}
.fc-kn-article:before {
  content: "\e914";
}
.fc-kn-update:before {
  content: "\e915";
}
.fc-kn-video:before {
  content: "\e916";
}
.fc-kn-support:before {
  content: "\e917";
}
.fc-kn-tutorials:before {
  content: "\e918";
}
.fc-kn-videos:before {
  content: "\e919";
}
.fc-close-discussion:before {
  content: "\e90a";
}
.fc-time-schift:before {
  content: "\e6a1";
}
.fc-pin:before {
  content: "\e909";
}
.fc-distribution:before {
  content: "\e907";
}
.fc-qr-code:before {
  content: "\e906";
}
.fc-smiley:before {
  content: "\e905";
}
.fc-location:before {
  content: "\e902";
}
.fc-pinterest:before {
  content: "\e903";
}
.fc-case:before {
  content: "\e66a";
}
.fc-lead-ads:before {
  content: "\e69b";
}
.fc-loading:before {
  content: "\e69c";
}
.fc-dropbox:before {
  content: "\e69a";
}
.fc-circle:before {
  content: "\e694";
}
.fc-external-link:before {
  content: "\e695";
}
.fc-list:before {
  content: "\e696";
}
.fc-monitoring:before {
  content: "\e945";
}
.fc-rss:before {
  content: "\e693";
}
.fc-openid:before {
  content: "\e68f";
}
.fc-tree-node:before {
  content: "\e68e";
}
.fc-tweet:before {
  content: "\e686";
}
.fc-pagegroup:before {
  content: "\e687";
}
.fc-hashtag:before {
  content: "\e685";
}
.fc-access:before {
  content: "\e682";
}
.fc-sweepstake:before {
  content: "\e681";
}
.fc-locked:before {
  content: "\e67d";
}
.fc-unlocked:before {
  content: "\e683";
}
.fc-review:before {
  content: "\e67b";
}
.fc-block:before {
  content: "\e67a";
}
.fc-help:before {
  content: "\e675";
}
.fc-mail:before {
  content: "\e673";
}
.fc-phone:before {
  content: "\e674";
}
.fc-copy:before {
  content: "\e672";
}
.fc-flag:before {
  content: "\e671";
}
.fc-beer:before {
  content: "\e670";
}
.fc-wordpress:before {
  content: "\e66f";
}
.fc-heart:before {
  content: "\e66e";
}
.fc-mobile:before {
  content: "\e69d";
}
.fc-upload:before {
  content: "\e66b";
}
.fc-view-compress:before {
  content: "\e65f";
}
.fc-view-expand:before {
  content: "\e660";
}
.fc-view-feed:before {
  content: "\e697";
}
.fc-view-analytics:before {
  content: "\e677";
}
.fc-view-tree:before {
  content: "\e94c";
}
.fc-view-grid:before {
  content: "\e662";
}
.fc-view-calendar-day:before {
  content: "\e90e";
}
.fc-view-calendar-week:before {
  content: "\e90f";
}
.fc-view-calendar:before {
  content: "\e661";
}
.fc-view-grid-lg:before {
  content: "\e663";
}
.fc-view-list:before {
  content: "\e664";
}
.fc-filmstrip:before {
  content: "\e666";
}
.fc-grid:before {
  content: "\e667";
}
.fc-grid-sm:before {
  content: "\e668";
}
.fc-grid-lg:before {
  content: "\e669";
}
.fc-folder-outline:before {
  content: "\e961";
}
.fc-folder:before {
  content: "\e65c";
}
.fc-dynamic-landing-page:before {
  content: "\e65d";
}
.fc-website-conversion:before {
  content: "\e65e";
}
.fc-mobile-app-engagement:before {
  content: "\e656";
}
.fc-suggestions:before {
  content: "\e657";
}
.fc-canvas-app-engagement:before {
  content: "\e658";
}
.fc-canvas-app-installs:before {
  content: "\e659";
}
.fc-post_pool:before {
  content: "\e65a";
}
.fc-website_clicks:before {
  content: "\e65b";
}
.fc-drag:before {
  content: "\e944";
}
.fc-drag2:before {
  content: "\e93f";
}
.fc-sidebar-close:before {
  content: "\e653";
}
.fc-sidebar-open:before {
  content: "\e654";
}
.fc-facelift-cloud:before {
  content: "\e64f";
}
.fc-dashboard:before {
  content: "\e604";
}
.fc-benchmarking:before {
  content: "\e93e";
}
.fc-publisher:before {
  content: "\e946";
}
.fc-communication:before {
  content: "\e602";
}
.fc-engagement:before {
  content: "\e606";
}
.fc-logout:before {
  content: "\e64e";
}
.fc-arrow-left:before {
  content: "\e60e";
}
.fc-arrow-right:before {
  content: "\e60f";
}
.fc-arrow-down:before {
  content: "\e610";
}
.fc-arrow-up:before {
  content: "\e611";
}
.fc-prev:before {
  content: "\e612";
}
.fc-next:before {
  content: "\e613";
}
.fc-up:before {
  content: "\e614";
}
.fc-down:before {
  content: "\e615";
}
.fc-private:before {
  content: "\e616";
}
.fc-user:before {
  content: "\e617";
}
.fc-warning:before {
  content: "\e618";
}
.fc-weibo:before {
  content: "\e904";
}
.fc-vk:before {
  content: "\e91e";
}
.fc-xing:before {
  content: "\e910";
}
.fc-google:before {
  content: "\e90b";
}
.fc-google-plus:before {
  content: "\e96b";
}
.fc-facebook:before {
  content: "\e619";
}
.fc-instagram:before {
  content: "\e67e";
}
.fc-youtube:before {
  content: "\e67f";
}
.fc-twitter:before {
  content: "\e645";
}
.fc-linkedin:before {
  content: "\e679";
}
.fc-download:before {
  content: "\e61a";
}
.fc-timing:before {
  content: "\e61b";
}
.fc-refresh:before {
  content: "\e61c";
}
.fc-share:before {
  content: "\e61d";
}
.fc-like:before {
  content: "\e61e";
}
.fc-dislike:before {
  content: "\e67c";
}
.fc-settings:before {
  content: "\e61f";
}
.fc-tags:before {
  content: "\e620";
}
.fc-close:before {
  content: "\e622";
}
.fc-search:before {
  content: "\e623";
}
.fc-remove:before {
  content: "\e698";
}
.fc-add:before {
  content: "\e624";
}
.fc-edit:before {
  content: "\e625";
}
.fc-delete:before {
  content: "\e621";
}
.fc-delete-deactivated:before {
  content: "\e927";
}
.fc-hide:before {
  content: "\e680";
}
.fc-preview:before {
  content: "\e626";
}
.fc-target:before {
  content: "\e627";
}
.fc-code:before {
  content: "\e628";
}
.fc-picture:before {
  content: "\e629";
}
.fc-promote:before {
  content: "\e62a";
}
.fc-reply:before {
  content: "\e62b";
}
.fc-reply-resolve:before {
  content: "\e94a";
}
.fc-success:before {
  content: "\e62c";
}
.fc-uncheck:before {
  content: "\e94b";
}
.fc-close-lg:before {
  content: "\e62d";
}
.fc-image:before {
  content: "\e62e";
}
.fc-audio:before {
  content: "\e62f";
}
.fc-video:before {
  content: "\e630";
}
.fc-flash:before {
  content: "\e631";
}
.fc-pdf:before {
  content: "\e632";
}
.fc-newfile:before {
  content: "\e633";
}
.fc-file:before {
  content: "\e634";
}
.fc-twitteraccount:before {
  content: "\e688";
}
.fc-fanpage:before {
  content: "\e635";
}
.fc-accountsettings:before {
  content: "\e636";
}
.fc-shorturl:before {
  content: "\e637";
}
.fc-eventhandler:before {
  content: "\e638";
}
.fc-textelements:before {
  content: "\e639";
}
.fc-feed:before {
  content: "\e63a";
}
.fc-analytics:before {
  content: "\e63b";
}
.fc-domains:before {
  content: "\e63c";
}
.fc-posts:before {
  content: "\e63d";
}
.fc-blogposts:before {
  content: "\e684";
}
.fc-calendar:before {
  content: "\e63e";
}
.fc-hub:before {
  content: "\e63f";
}
.fc-info:before {
  content: "\e640";
}
.fc-users:before {
  content: "\e641";
}
.fc-action:before {
  content: "\e642";
}
.fc-star:before {
  content: "\e643";
}
.fc-retweet:before {
  content: "\e644";
}
.fc-comments:before {
  content: "\e646";
}
.fc-messages:before {
  content: "\e647";
}
.fc-discussion:before {
  content: "\e648";
}
.fc-expand:before {
  content: "\e649";
}
.fc-collapse:before {
  content: "\e64a";
}
.fc-domain:before {
  content: "\e64d";
}
.fc-more:before {
  content: "\e650";
}
.fc-change:before {
  content: "\e651";
}
.fc-template:before {
  content: "\e652";
}
.fc-sm-fb-comment:before {
  content: "\e978";
  color: #616770;
}
.fc-sm-fb-reaction-active:before {
  content: "\e979";
}
.fc-sm-fb-reaction:before {
  content: "\e97a";
}
.fc-sm-fb-share:before {
  content: "\e97b";
}
.fc-sm-locked:before {
  content: "\e972";
}
.fc-reference:before {
  content: "\e91d";
}
.fc-kn-faq:before {
  content: "\e91a";
}
.fc-sm-timing:before {
  content: "\e692";
}
.fc-sm-comments:before {
  content: "\e689";
}
.fc-sm-users:before {
  content: "\e68b";
}
.fc-sm-like:before {
  content: "\e68a";
}
.fc-sm-dislike:before {
  content: "\e900";
}
.fc-sm-reply:before {
  content: "\e690";
}
.fc-sm-share:before {
  content: "\e68d";
}
.fc-sm-heart:before {
  content: "\e901";
}
.fc-sm-circle:before {
  content: "\e699";
}
.fc-sm-remove:before {
  content: "\e676";
}
.fc-sm-arrow-down:before {
  content: "\e60a";
}
.fc-sm-arrow-up:before {
  content: "\e60d";
}
.fc-sm-arrow-left:before {
  content: "\e60b";
}
.fc-sm-arrow-right:before {
  content: "\e60c";
}
.fc-sm-last:before {
  content: "\e66c";
}
.fc-sm-first:before {
  content: "\e66d";
}
.fc-sm-drag:before {
  content: "\e655";
}
.fc-sm-weibo:before {
  content: "\e91f";
}
.fc-sm-vk:before {
  content: "\e920";
}
.fc-sm-xing:before {
  content: "\e911";
}
.fc-sm-google-plus:before {
  content: "\e90c";
}
.fc-sm-google:before {
  content: "\e96c";
}
.fc-sm-facebook:before {
  content: "\e6a0";
}
.fc-sm-instagram:before {
  content: "\e921";
}
.fc-sm-twitter:before {
  content: "\e69f";
}
.fc-sm-reference-period:before {
  content: "\e90d";
}
.fc-sm-preview:before {
  content: "\e68c";
}
.fc-sm-retweet:before {
  content: "\e69e";
}
.fc-sm-expand:before {
  content: "\e64b";
}
.fc-sm-collapse:before {
  content: "\e64c";
}
.fc-xl-add:before {
  content: "\e665";
}
